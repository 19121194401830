// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
import React from 'react'

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Comment */
const Comment = React.memo(({ form, form: { getFieldProps: apply } }) => (
  <div className="group two-span">
    <label className="description" htmlFor="description">
      Description
    </label>
    <textarea
      id="description"
      name="description"
      rows="5"
      {...apply('description')}
    />
    {form.touched.description && form.errors.description ? (
      <p className="error description">{form.errors.description}</p>
    ) : null}
  </div>
))

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default Comment
