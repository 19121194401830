// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React, { useState } from 'react'

import isUndefined from 'lodash/isUndefined'
import split from 'lodash/split'
import join from 'lodash/join'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { useFormik } from 'formik'
import * as Yup from 'yup'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import {
  name,
  email,
  description,
  keywords as validateKeywords,
} from '../form/methods/default-schema'

import NameInput from '../form/fields/name-input'
import EmailInput from '../form/fields/email-input'
import AlternateTitleInput from '../form/fields/alternate-title-input'
import KeywordsSelect from '../form/fields/keywords-select'
import AuthorsSelect from '../form/fields/authors-select'
import CategoriesSelect from '../form/fields/categories-select'
import YearSelect from '../form/fields/year-select'
import EventSpanPicker from '../form/fields/event-span-picker'
import DescriptionTextarea from '../form/fields/description-textarea'

import SubmitButton from '../form/submit-button'
import Tracker from '../form/tracker'

import debounceForm from '../form/methods/debounce-form'
import makeQueryString from '../form/methods/make-query-string'

import '../form/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React
const macroId =
  'AKfycbyTuAfy_fHq2np0r3LkKx0XuXyYuOqsGUCRkg5fNjSC9Dtmt_QIFJ_pr4pgfSssN37X'

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** ContactForm */
const ContactForm = ({ arkRef, keywords: previouslyAnnotatedKeywords }) => {
  const [state, setState] = useState({
    serverMessage: 'Not yet initialized.',
    started: false,
    finished: false,
  })

  const form = useFormik({
    initialValues: {
      name: '',
      email: '',
      description: '',
      keywords: join(previouslyAnnotatedKeywords, ','),
    },
    validationSchema: Yup.object().shape({
      name,
      email,
      description,
      keywords: validateKeywords,
    }),
    onSubmit: (values) => {
      debounceForm(setState, () => {
        const toSend = {
          ...values,
          arkRef,
          context: 'Contact Form',
        }
        const queryString = makeQueryString(toSend)

        fetch(
          `https://script.google.com/macros/s/${macroId}/exec?${queryString}callback=?`,
          {
            method: 'GET',
            mode: 'no-cors',
          }
        )
          .then((response) => {
            setState({
              serverMessage: 'Done...',
              started: true,
              finished: true,
            })
            if (isUndefined(window) === false) {
              window.scrollTo(0, 0)
            }
          })
          .catch((error) => {
            setState({
              serverMessage: 'Error...',
              started: true,
              finished: true,
            })
          })
      })
    },
  })

  // console.debug(form)
  // console.debug(form.values)

  return (
    <Fragment>
      <form onSubmit={form.handleSubmit} id="contact-form" className="x-form c">
        <section className="two-col">
          <NameInput form={form} strongHint="Required input" />
          <EmailInput form={form} strongHint="Required input" />
          <AlternateTitleInput form={form} />
          <CategoriesSelect form={form} />
          <KeywordsSelect form={form} />
          <AuthorsSelect form={form} />
          <YearSelect form={form} />
          <EventSpanPicker form={form} />
          <DescriptionTextarea form={form} />
        </section>
        <SubmitButton form={form} state={state} />
      </form>
      <Tracker form={form} state={state} requiredFields={['name', 'email']} />
    </Fragment>
  )
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default ContactForm
