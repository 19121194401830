// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
import React from 'react'

import map from 'lodash/map'
import split from 'lodash/split'
import join from 'lodash/join'
import is from 'lodash/isUndefined'

import Select from 'antd/lib/select'
import 'antd/lib/select/style/css'

import years from '../../../data/years.json'

const { Option } = Select

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** YearSelect */
const YearSelect = React.memo(
  ({
    form,
    form: {
      setFieldValue,
      initialValues: { year: initialYear },
    },
  }) => (
    <div className="group">
      <label className="year" htmlFor="year">
        Year
      </label>
      <Select
        style={{
          width: '100%',
        }}
        placeholder="Please select"
        defaultValue={is(initialYear) === false ? initialYear : undefined}
        onChange={(value) => {
          setFieldValue('years', join(value, ','))
        }}
      >
        {map(years, (years) => (
          <Option key={years.title}>{years.title}</Option>
        ))}
      </Select>
      {form.touched.years && form.errors.years ? (
        <p className="error years">{form.errors.years}</p>
      ) : null}
    </div>
  )
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default YearSelect
