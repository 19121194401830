// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
import React from 'react'

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** AlternateTitle */
const AlternateTitle = React.memo(
  ({ form, form: { getFieldProps: apply } }) => (
    <div className="group two-span">
      <label className="alternate-title" htmlFor="alternateTitle">
        Suggest a different title
      </label>
      <input
        id="alternate-title"
        name="alternate-title"
        type="text"
        {...apply('alternateTitle')}
      />
      {form.touched.alternateTitle && form.errors.alternateTitle ? (
        <p className="error address-street-1">{form.errors.alternateTitle}</p>
      ) : null}
    </div>
  )
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default AlternateTitle
