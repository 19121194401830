// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
import React from 'react'

import map from 'lodash/map'
import split from 'lodash/split'
import join from 'lodash/join'
import is from 'lodash/isUndefined'

import Select from 'antd/lib/select'
import 'antd/lib/select/style/css'

import keywords from '../../../data/keywords.json'

const { Option } = Select

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** KeywordsSelect */
const KeywordsSelect = React.memo(
  ({
    form,
    form: {
      setFieldValue,
      initialValues: { keywords: initialKeywords },
    },
  }) => (
    <div className="group two-span">
      <label className="keywords" htmlFor="keywords">
        Keywords
      </label>
      <Select
        mode="multiple"
        allowClear
        style={{
          width: '100%',
        }}
        placeholder="Please select"
        defaultValue={
          is(initialKeywords) === false
            ? split(initialKeywords, ',')
            : undefined
        }
        onChange={(value) => {
          setFieldValue('keywords', join(value, ','))
        }}
      >
        {map(keywords, (keyword) => (
          <Option key={keyword.slug}>{keyword.title}</Option>
        ))}
      </Select>
      {form.touched.keywords && form.errors.keywords ? (
        <p className="error keywords">{form.errors.keywords}</p>
      ) : null}
    </div>
  )
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default KeywordsSelect
