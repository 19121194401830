// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

import head from 'lodash/head'
import last from 'lodash/last'
import isNull from 'lodash/isNull'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import split from 'lodash/split'
import trim from 'lodash/trim'
import kebabCase from 'lodash/kebabCase'
import join from 'lodash/join'
import replace from 'lodash/replace'

import moment from 'moment'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import copy from 'copy-to-clipboard'

import { graphql } from 'gatsby'

import Row from 'antd/lib/row'
import 'antd/lib/row/style/css'

import Col from 'antd/lib/col'
import 'antd/lib/col/style/css'

import Tabs from 'antd/lib/tabs'
import 'antd/lib/tabs/style/css'

import Tooltip from 'antd/lib/tooltip'
import 'antd/lib/tooltip/style/css'

import notification from 'antd/lib/notification'
import 'antd/lib/notification/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import ImageList from './image-list'

import TopBar from '../top-bar'
import '../top-bar/style.less'

import ArkImg from '../ark-img'
import '../ark-img/style.less'

import ContactForm from '../contact-form'
import '../contact-form/style.less'

import Slider from '../slider'
import '../slider/style.less'

import Link from '../link'
import '../link/style.less'

import StandardPageWrapper from '../standard-page-wrapper'
import '../standard-page-wrapper/style.less'

import './style.less'

import flattenGatsbyNode from '../../methods/flatten-gatsby-node'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React
const { TabPane } = Tabs

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const pageQuery = graphql`
  query ArkPageQuery($pathAuto: String) {
    thisPage: allFile(filter: { fields: { pathAuto: { eq: $pathAuto } } }) {
      edges {
        node {
          fields {
            pathAuto
            breadcrumbsAuto {
              title
              slug
            }
          }
          childResources {
            fileRef
            arkRef
            title
            description
            keywords
            categories
            authors
            people
            published
            lastEdited
            partOfCollections
            height
            width
            year
            decade
            ar
            relatedObjects {
              byCategory {
                similarity
                same
                obj {
                  fileRef
                  arkRef
                  title
                  ar
                }
              }
              byKeywords {
                similarity
                same
                obj {
                  fileRef
                  arkRef
                  title
                  ar
                }
              }
              byAuthors {
                similarity
                same
                obj {
                  fileRef
                  arkRef
                  title
                  ar
                }
              }
              byYear {
                similarity
                same
                obj {
                  fileRef
                  arkRef
                  title
                  ar
                }
              }
              byDecade {
                similarity
                same
                obj {
                  fileRef
                  arkRef
                  title
                  ar
                }
              }
            }
          }
        }
      }
    }
    similar: file(relativePath: { eq: "similar.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 100
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    same: file(relativePath: { eq: "same.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 100
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = ({ data, pageContext, ...props }) => {
  const {
    thisPage: { edges },
    similar: similarSymbol,
    same: sameSymbol,
    node = flattenGatsbyNode(edges[0].node),
  } = data
  const {
    fileRef,
    arkRef,
    title,
    description,
    keywords,
    categories,
    pathAuto,
    breadcrumbsAuto,
    authors,
    people,
    published,
    lastEdited,
    partOfCollections,
    height,
    width,
    year,
    decade,
    ar,
    relatedObjects,
  } = node
  const breadcrumbs = [head(breadcrumbsAuto), last(breadcrumbsAuto)]

  const pageSchema = {
    pageTitle: title,
    slug: pathAuto.substring(1),
    abstract: title,
    breadcrumbs,
  }

  let authorsArray = []
  if (isEmpty(authors) === false) {
    authorsArray = map(split(authors, ','), trim)
  }

  const categoriesArray = map(categories, (a) => ({
    title: join(a, ' » '),
    slug: join(map(a, kebabCase), '/'),
  }))

  const publishedMoment = moment(published)
  const lastEditedMoment = moment(lastEdited)

  let collectionsArray = []
  if (isEmpty(partOfCollections) === false) {
    collectionsArray = map(map(split(partOfCollections, ','), trim), (s) => ({
      title: s,
      slug: `https://www.auroville-archives.org/en/collections/${kebabCase(s)}`,
    }))
  }

  const [publisherRef, archiveRef, fileIdRef, storeRef] = split(fileRef, '.')
  const cite = `"${title}," ${year}, web.auroville-archives${pathAuto}`

  console.debug(year, decade)

  return (
    <StandardPageWrapper
      className="ark-page"
      pageSchema={pageSchema}
      {...props}
    >
      <TopBar title={title} breadcrumbsAuto={breadcrumbs} layouts={false} />
      <ArkImg
        fileRef={fileRef}
        aspectRatio={ar}
        width={900}
        widths={[100, 200, 900]}
        title={title}
      />
      {isNull(description) === false && isEmpty(description) === false && (
        <Fragment>
          <h2 className="as-h3">Description</h2>
          <p className="plsm">{description}</p>
        </Fragment>
      )}
      {isNull(year) === false ||
        isEmpty(year) === false ||
        isNull(decade) === false ||
        (isEmpty(decade) === false && (
          <Fragment>
            <h2 className="as-h3">Timestamps</h2>
            <p className="plsm">
              {isNull(year) === false && isEmpty(year) === false && (
                <Fragment>
                  <strong>Year:</strong>&nbsp;
                  <Link to={`/years/${kebabCase(year)}/page-1`}>{year}</Link>
                  <br />
                </Fragment>
              )}
              {isNull(decade) === false && isEmpty(decade) === false && (
                <Fragment>
                  <strong>Decade:</strong>&nbsp;
                  <Link to={`/decades/${kebabCase(decade)}/page-1`}>
                    {decade}
                  </Link>
                </Fragment>
              )}
            </p>
          </Fragment>
        ))}
      <h2 className="as-h3">Categorisation</h2>
      <p className="plsm">
        {map(categoriesArray, ({ title, slug }) => (
          <Link
            to={`/categories/${slug}/page-1`}
            style={{ marginBottom: '0.5rem', display: 'block' }}
          >
            {title}
          </Link>
        ))}
      </p>
      {isNull(keywords) === false && isEmpty(keywords) === false && (
        <Fragment>
          <h2 className="as-h3">Keywords</h2>
          <p className="plsm">
            {map(keywords, (keyword) => (
              <Fragment>
                <Link
                  to={`/keywords/${kebabCase(keyword)}/page-1`}
                  className="as-tag overwrite"
                >
                  {keyword}
                </Link>
                &nbsp;
              </Fragment>
            ))}
          </p>
        </Fragment>
      )}
      {isEmpty(authors) === false && (
        <Fragment>
          <h2 className="as-h3">Author/s</h2>
          <p className="plsm">
            {map(authorsArray, (author) => (
              <Fragment>
                <Link to={`/authors/${kebabCase(author)}/page-1`}>
                  {author}
                </Link>
                &nbsp;
              </Fragment>
            ))}
          </p>
        </Fragment>
      )}
      {isEmpty(partOfCollections) === false && (
        <Fragment>
          <h2 className="as-h3">In collections</h2>
          <p className="plsm">
            {map(collectionsArray, ({ title, slug }) => (
              <Link
                to={slug}
                style={{ marginBottom: '0.25rem', display: 'block' }}
              >
                {title}&nbsp;&nbsp;➱
              </Link>
            ))}
          </p>
        </Fragment>
      )}
      <h3>In Auroville Archives since</h3>
      <p className="c plsm">
        {publishedMoment.format('MMMM YYYY')} ({publishedMoment.fromNow()})
      </p>
      <div className="separator">
        <h2 className="unset">Using this media</h2>
        <h3>License</h3>
        <p className="c plsm">
          This media object is available under the{' '}
          <Link to="https://creativecommons.org/licenses/by-nc-sa/4.0/">
            <strong>
              Creative Commons "Attribution-NonCommercial-ShareAlike 4.0"
              International
            </strong>
            &nbsp;or&nbsp;<strong>CC BY-NC-SA 4.0</strong>&nbsp;
          </Link>
          license.
        </p>
        <p className="c plsm">
          You are free to use, remix, adapt, and build upon this object&nbsp;
          <u>non-commercially</u>, as long as you credit Auroville Archives and
          license your new creation under identical terms.&nbsp;
          <Link to="https://creativecommons.org/licenses/by-nc-sa/4.0/">
            Read license terms ➱
          </Link>
        </p>
        <h3>How to cite</h3>
        <p className="c plsm">
          If you use this media object in your work you must cite it. You can
          copy the citation by clicking on the link below.
        </p>
        <p className="c plsm">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault()
              copy(cite)
              notification.open({
                className: 'notice mask-p',
                message: false,
                description: 'Copied to clipboard',
                placement: 'bottomRight',
              })
            }}
          >
            {cite}
          </a>
        </p>
        <h3>Available formats and sizes</h3>
        <ImageList fileRef={fileRef} />
        {width >= 1920 && (
          <Fragment>
            <h3>Higher resolution</h3>
            <p className="c plsm">
              This media object is also available in a higher resolution (
              {width} X {height}.) To request this, please write to us.
            </p>
          </Fragment>
        )}
      </div>
      <div className="separator">
        <h2 className="unset">Persistent identifier</h2>
        <p className="c">
          Auroville Archives implements the&nbsp;
          <Link to="https://arks.org/">
            Archival Resource Keys (ARKs) scheme
          </Link>
          . ARKs serve as open, mainstream, non-paywalled, decentralized,
          stable, trusted and <i>persistent</i> identifiers for media objects.
          End users, especially researchers, rely on ARKs for long term access
          to the global scientific and cultural records.&nbsp;
          <Link to="https://arks.org/">Learn more ➱</Link>
        </p>
        <p>
          <strong>ARK reference:</strong>&nbsp;
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault()
              copy(`https://n2t.net/ark:/64641/${fileIdRef}`)
              notification.open({
                className: 'notice mask-p',
                message: false,
                description: 'Copied to clipboard',
                placement: 'bottomRight',
              })
            }}
          >{`n2t.net/ark:/64641/${fileIdRef}`}</a>
          <br />
          <strong>File reference:</strong>&nbsp;
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault()
              copy(fileRef)
              notification.open({
                className: 'notice mask-p',
                message: false,
                description: 'Copied to clipboard',
                placement: 'bottomRight',
              })
            }}
          >
            {fileRef}
          </a>
        </p>
      </div>
      <div className="separator">
        <h2 className="unset">Contribute details for this image</h2>
        <p className="c">
          Dear friends – do you recognize this photo? Can you tell us more about
          it? Add what you remember to the Archives by filling out this form.
        </p>
        <ContactForm arkRef={fileIdRef} keywords={keywords} />
      </div>
      <div className="separator">
        <h2 className="unset">Related</h2>
        <Tabs defaultActiveKey="byCategories" className="related-objects">
          <TabPane tab="By Categories" key="byCategories">
            <Slider
              className="ext-arrow-opaque"
              conf={{
                slidesToShow: 2,
                slidesToScroll: 2,
                lazyLoad: true,
              }}
              Renderer={({
                node: {
                  similarity,
                  same,
                  obj: { fileRef, ar, title },
                },
              }) => (
                <Fragment>
                  <ArkImg
                    fileRef={fileRef}
                    aspectRatio={ar}
                    width={900}
                    widths={[100, 200, 900]}
                    title={title}
                  />
                  <div className="info">
                    <h3 className="unset">{title}</h3>
                    {same === true ? (
                      <GatsbyImage image={getImage(sameSymbol)} />
                    ) : (
                      <GatsbyImage image={getImage(similarSymbol)} />
                    )}
                  </div>
                </Fragment>
              )}
              nodes={relatedObjects.byKeywords}
            />
          </TabPane>
          <TabPane tab="By Keywords" key="byKeywords">
            <Slider
              className="ext-arrow-opaque"
              conf={{
                slidesToShow: 2,
                slidesToScroll: 2,
                lazyLoad: true,
              }}
              Renderer={({
                node: {
                  similarity,
                  same,
                  obj: { fileRef, ar, title },
                },
              }) => (
                <Fragment>
                  <ArkImg
                    fileRef={fileRef}
                    aspectRatio={ar}
                    width={900}
                    widths={[100, 200, 900]}
                    title={title}
                  />
                  <div className="info">
                    <h3 className="unset">{title}</h3>
                    {same === true ? (
                      <GatsbyImage image={getImage(sameSymbol)} />
                    ) : (
                      <GatsbyImage image={getImage(similarSymbol)} />
                    )}
                  </div>
                </Fragment>
              )}
              nodes={relatedObjects.byCategory}
            />
          </TabPane>
          <TabPane tab="By Authors" key="byAuthors">
            <Slider
              className="ext-arrow-opaque"
              conf={{
                slidesToShow: 2,
                slidesToScroll: 2,
                lazyLoad: true,
              }}
              Renderer={({
                node: {
                  similarity,
                  same,
                  obj: { fileRef, ar, title },
                },
              }) => (
                <Fragment>
                  <ArkImg
                    fileRef={fileRef}
                    aspectRatio={ar}
                    width={900}
                    widths={[100, 200, 900]}
                    title={title}
                  />
                  <div className="info">
                    <h3 className="unset">{title}</h3>
                    {same === true ? (
                      <GatsbyImage image={getImage(sameSymbol)} />
                    ) : (
                      <GatsbyImage image={getImage(similarSymbol)} />
                    )}
                  </div>
                </Fragment>
              )}
              nodes={relatedObjects.byAuthors}
            />
          </TabPane>
          <TabPane tab="By Year" key="byYear">
            <Slider
              className="ext-arrow-opaque"
              conf={{
                slidesToShow: 2,
                slidesToScroll: 2,
                lazyLoad: true,
              }}
              Renderer={({
                node: {
                  similarity,
                  same,
                  obj: { fileRef, ar, title },
                },
              }) => (
                <Fragment>
                  <ArkImg
                    fileRef={fileRef}
                    aspectRatio={ar}
                    width={900}
                    widths={[100, 200, 900]}
                    title={title}
                  />
                  <div className="info">
                    <h3 className="unset">{title}</h3>
                    {same === true ? (
                      <GatsbyImage image={getImage(sameSymbol)} />
                    ) : (
                      <GatsbyImage image={getImage(similarSymbol)} />
                    )}
                  </div>
                </Fragment>
              )}
              nodes={relatedObjects.byYear}
            />
          </TabPane>
          <TabPane tab="By Decade" key="byDecade">
            <Slider
              className="ext-arrow-opaque"
              conf={{
                slidesToShow: 2,
                slidesToScroll: 2,
                lazyLoad: true,
              }}
              Renderer={({
                node: {
                  similarity,
                  same,
                  obj: { fileRef, ar, title },
                },
              }) => (
                <Fragment>
                  <ArkImg
                    fileRef={fileRef}
                    aspectRatio={ar}
                    width={900}
                    widths={[100, 200, 900]}
                    title={title}
                  />
                  <div className="info">
                    <h3 className="unset">{title}</h3>
                    {same === true ? (
                      <GatsbyImage image={getImage(sameSymbol)} />
                    ) : (
                      <GatsbyImage image={getImage(similarSymbol)} />
                    )}
                  </div>
                </Fragment>
              )}
              nodes={relatedObjects.byDecade}
            />
          </TabPane>
        </Tabs>
      </div>
      <div className="separator">
        <h2>TODO: From the wiki</h2>
        <Tabs defaultActiveKey="byCategories" className="related-objects">
          <TabPane tab="Page 1" key="page1">
            Raw:
            https://wiki.auroville.org.in/w/api.php?action=parse&origin=*&format=json&page=1972
            Source: https://wiki.auroville.org.in/wiki/1972
            <p>
              (Ruud Lohman, 1 January 1972:) “This morning at 6.30 we had a New
              Year's celebration at Matrimandir. On the bottom, now 9 metres
              deep, four large fires were lit and all the workers gathered
              around them and on the various ledges which run around the
              excavation at different heights. Many people from the Auroville
              communities and from Pondy came, too. The first rays of the sun
              emerged. Then we played the New Year's music, as an experiment,
              for we were not sure how the workers would react. But they got
              more and more quiet and seemed to experience it intensively.
              Somebody gave a five minutes talk in Tamil on the meaning of
              Matrimandir and their work and explained the idea of the Centenary
              Year. When sweets were distributed they got so much into it that
              they started making it into a festival. They lost all idea of work
              and after two hours they left and we also took the day off. This
              morning was a clear symbol of what has been established during the
              last six weeks: a good understanding between the Tamilians and the
              Aurovilians. The work has gone beautifully, the first phase of
              Matrimandir will be completed this week; the huge crater is ready.
              It is splendid, great. It reminds many visitors of the
              construction of the pyramids, of the Colosseum, of cathedrals,
              really a monument that embodies a new age.”[1]
            </p>
            <p>WIP...</p>
          </TabPane>
          <TabPane tab="Page 2" key="page2">
            Raw:
            https://wiki.auroville.org.in/w/api.php?action=parse&origin=*&format=json&page=1972
            Source: https://wiki.auroville.org.in/wiki/1972
            <p>
              (Ruud Lohman, 1 January 1972:) “This morning at 6.30 we had a New
              Year's celebration at Matrimandir. On the bottom, now 9 metres
              deep, four large fires were lit and all the workers gathered
              around them and on the various ledges which run around the
              excavation at different heights. Many people from the Auroville
              communities and from Pondy came, too. The first rays of the sun
              emerged. Then we played the New Year's music, as an experiment,
              for we were not sure how the workers would react. But they got
              more and more quiet and seemed to experience it intensively.
              Somebody gave a five minutes talk in Tamil on the meaning of
              Matrimandir and their work and explained the idea of the Centenary
              Year. When sweets were distributed they got so much into it that
              they started making it into a festival. They lost all idea of work
              and after two hours they left and we also took the day off. This
              morning was a clear symbol of what has been established during the
              last six weeks: a good understanding between the Tamilians and the
              Aurovilians. The work has gone beautifully, the first phase of
              Matrimandir will be completed this week; the huge crater is ready.
              It is splendid, great. It reminds many visitors of the
              construction of the pyramids, of the Colosseum, of cathedrals,
              really a monument that embodies a new age.”[1]
            </p>
            <p>WIP...</p>
          </TabPane>
        </Tabs>
      </div>
    </StandardPageWrapper>
  )
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
