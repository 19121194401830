// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
import React from 'react'

import is from 'lodash/isUndefined'

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Name */
const Name = React.memo(
  ({
    hint = undefined,
    strongHint = undefined,
    form: {
      getFieldProps,
      errors: { email: nameErrors },
      touched: { name: nameTouched },
    },
  }) => (
    <div className="group">
      <label className="name" htmlFor="name">
        Your name
      </label>
      <input id="name" name="name" type="text" {...getFieldProps('name')} />
      {is(hint) === false ||
        (is(strongHint) === false && (
          <p className="hint email">
            {is(hint) === false && hint}
            {is(hint) === false && is(strongHint) === false && <br />}
            {is(strongHint) === false && <strong>{strongHint}</strong>}
          </p>
        ))}
      {nameTouched && nameErrors ? (
        <p className="error name">{nameErrors}</p>
      ) : null}
    </div>
  )
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default Name
