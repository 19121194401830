// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import isTouchedAndCanShowErrors from './methods/is-touched-and-can-show-errors'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
const Tracker = React.memo(({ form, state, requiredFields }) => {
  const { errors, isValid } = form
  const { started, finished, serverMessage } = state
  const canShowError = isTouchedAndCanShowErrors(form, requiredFields)

  return (
    <div className="tracker">
      {canShowError === true && (
        <Fragment>
          {isValid === false && (
            <p>
              There are {Object.keys(errors).length} errors. Please review your
              inputs.
            </p>
          )}
        </Fragment>
      )}
      {started === true && <p>{serverMessage}</p>}
    </div>
  )
})

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default Tracker
