// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
import React from 'react'

import map from 'lodash/map'
import split from 'lodash/split'
import join from 'lodash/join'
import is from 'lodash/isUndefined'

import TreeSelect from 'antd/lib/tree-select'
import 'antd/lib/tree-select/style/css'

import categories from '../../../data/categories.json'

const { TreeNode } = TreeSelect

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** KeywordsSelect */
const KeywordsSelect = React.memo(
  ({
    form,
    form: {
      setFieldValue,
      initialValues: { categories: initialCategories },
    },
  }) => (
    <div className="group two-span">
      <label className="categories" htmlFor="categories">
        Categories
      </label>
      <TreeSelect
        showSearch
        style={{
          width: '100%',
        }}
        dropdownStyle={{
          maxHeight: 600,
          overflow: 'auto',
        }}
        placeholder="Please select"
        allowClear
        multiple
        treeDefaultExpandAll
        defaultValue={
          is(initialCategories) === false
            ? split(initialCategories, ',')
            : undefined
        }
        onChange={(value) => {
          setFieldValue('categories', join(value, ','))
        }}
      >
        {map(categories, ({ key: fk, title: ft, children: sc }) => (
          <TreeNode value={fk} title={ft}>
            {map(sc, ({ key: sk, title: st, children: ssc }) => (
              <TreeNode value={sk} title={st}>
                {map(ssc, ({ key: tk, title: tt }) => (
                  <TreeNode value={tk} title={tt} />
                ))}
              </TreeNode>
            ))}
          </TreeNode>
        ))}
      </TreeSelect>
      {form.touched.categories && form.errors.categories ? (
        <p className="error keywords">{form.errors.categories}</p>
      ) : null}
    </div>
  )
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default KeywordsSelect
