// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import * as Yup from 'yup'

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------- Function
// ----------------------------------------------------------------------------
/** name */
export const name = Yup.string().trim().required('Please enter your name.')

/** email */
export const email = Yup.string()
  .trim()
  .required('Please enter your email.')
  .email('Should be a valid email')

/** comment */
export const comment = Yup.string().trim().nullable().notRequired()

/** description */
export const description = Yup.string().trim().nullable().notRequired()

/** description */
export const keywords = Yup.string().trim().nullable().notRequired()

/** comment */
export const subscribeToNewsletter = Yup.string()
  .trim()
  .nullable()
  .notRequired()

/** addressStreet1 */
export const addressStreet1 = Yup.string().trim().nullable().notRequired()

/** addressStreet2 */
export const addressStreet2 = Yup.string().trim().nullable().notRequired()

/** addressLandmark */
export const addressLandmark = Yup.string().trim().nullable().notRequired()

/** addressState */
export const addressState = Yup.string().trim().nullable().notRequired()

/** addressCountry */
export const addressCountry = Yup.string().trim().nullable().notRequired()

/** addressPostcode */
export const addressPostcode = Yup.string().trim().nullable().notRequired()

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
// export default name
