// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
import getRandomArbitraryInt from '../../../methods/get-random-arbitrary-int'

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------- Function
// ----------------------------------------------------------------------------
/** debounceForm */
const debounceForm = (setState, fx) => {
  setState({ serverMessage: 'Working...', started: true })
  setTimeout(() => {
    setState({ serverMessage: 'Sending data...', started: true })
    setTimeout(() => {
      setState({ serverMessage: 'Waiting...', started: true })
      setTimeout(() => {
        setState({
          serverMessage: 'Recieved response...',
          started: true,
          finished: true,
        })
        setTimeout(() => {
          fx()
        }, getRandomArbitraryInt(700, 1200))
      }, getRandomArbitraryInt(700, 1200))
    }, getRandomArbitraryInt(700, 1200))
  }, getRandomArbitraryInt(700, 1200))
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default debounceForm
