// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
import React from 'react'

import DatePicker from 'antd/lib/date-picker'
import 'antd/lib/date-picker/style/css'

import years from '../../../data/years.json'

const { RangePicker } = DatePicker

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** YearSelect */
const YearSelect = React.memo(
  ({
    form,
    form: {
      setFieldValue,
      initialValues: { eventSpan: initialEventSpan },
    },
  }) => (
    <div className="group two-span">
      <label className="event-span" htmlFor="event-span">
        Event span (or possible year range)
      </label>
      <RangePicker style={{ width: '100%' }} suffixIcon={false} />
      {form.touched.eventSpan && form.errors.eventSpan ? (
        <p className="error event-span">{form.errors.eventSpan}</p>
      ) : null}
    </div>
  )
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default YearSelect
