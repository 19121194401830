// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
import React from 'react'

import map from 'lodash/map'
import split from 'lodash/split'
import join from 'lodash/join'
import is from 'lodash/isUndefined'

import Select from 'antd/lib/select'
import 'antd/lib/select/style/css'

import authors from '../../../data/authors.json'

const { Option } = Select

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** AuthorsSelect */
const AuthorsSelect = React.memo(
  ({
    form,
    form: {
      setFieldValue,
      initialValues: { authors: initialAuthors = undefined },
    },
  }) => (
    <div className="group">
      <label className="authors" htmlFor="authors">
        Author
      </label>
      <Select
        mode="multiple"
        allowClear
        style={{
          width: '100%',
        }}
        placeholder="Please select"
        defaultValue={
          is(initialAuthors) === false ? split(initialAuthors, ',') : undefined
        }
        onChange={(value) => {
          setFieldValue('authors', join(value, ','))
        }}
      >
        {map(authors, (author) => (
          <Option key={author.title}>{author.title}</Option>
        ))}
      </Select>
      {form.touched.authors && form.errors.authors ? (
        <p className="error authors">{form.errors.authors}</p>
      ) : null}
    </div>
  )
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default AuthorsSelect
