// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
import keys from 'lodash/keys'
import difference from 'lodash/difference'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------- Function
// ----------------------------------------------------------------------------
/** isTouchedAndCanShowErrors */
const isTouchedAndCanShowErrors = (form, requiredFields) =>
  Object.keys(difference(requiredFields, keys(form.touched))).length === 0

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default isTouchedAndCanShowErrors
