// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import split from 'lodash/split'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Link from '../link'
import '../link/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** ImageList */
const ImageList = ({ fileRef }) => {
  const [publisherRef, archiveRef, fileIdRef, storeRef] = split(fileRef, '.')

  return (
    <p className="plsm">
      <strong>JPG:</strong>
      <Link
        to={`https://${storeRef}.store.auroville-archives.org/${fileIdRef}/200w.jpg`}
      >
        200w
      </Link>
      ,&nbsp;
      <Link
        to={`https://${storeRef}.store.auroville-archives.org/${fileIdRef}/900w.jpg`}
      >
        900w
      </Link>
      ,&nbsp;
      <Link
        to={`https://${storeRef}.store.auroville-archives.org/${fileIdRef}/1440w.jpg`}
      >
        1440w
      </Link>
      ,&nbsp;
      <Link
        to={`https://${storeRef}.store.auroville-archives.org/${fileIdRef}/1920w.jpg`}
      >
        1920w
      </Link>
      <br />
      <strong>WEBP:</strong>
      &nbsp;
      <Link
        to={`https://${storeRef}.store.auroville-archives.org/${fileIdRef}/900w.webp`}
      >
        200w
      </Link>
      ,&nbsp;
      <Link
        to={`https://${storeRef}.store.auroville-archives.org/${fileIdRef}/900w.webp`}
      >
        900w
      </Link>
      ,&nbsp;
      <Link
        to={`https://${storeRef}.store.auroville-archives.org/${fileIdRef}/1440w.webp`}
      >
        1440w
      </Link>
      ,&nbsp;
      <Link
        to={`https://${storeRef}.store.auroville-archives.org/${fileIdRef}/1920w.webp`}
      >
        1920w
      </Link>
      <br />
      <strong>AVIF:</strong>
      &nbsp;
      <Link
        to={`https://${storeRef}.store.auroville-archives.org/${fileIdRef}/200w.avif`}
      >
        200w
      </Link>
      ,&nbsp;
      <Link
        to={`https://${storeRef}.store.auroville-archives.org/${fileIdRef}/900w.avif`}
      >
        900w
      </Link>
      ,&nbsp;
      <Link
        to={`https://${storeRef}.store.auroville-archives.org/${fileIdRef}/1440w.avif`}
      >
        1440w
      </Link>
      ,&nbsp;
      <Link
        to={`https://${storeRef}.store.auroville-archives.org/${fileIdRef}/1920w.avif`}
      >
        1920w
      </Link>
    </p>
  )
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default ImageList
