// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
import React from 'react'

import is from 'lodash/isUndefined'

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Email */
const Email = React.memo(
  ({
    hint = undefined,
    strongHint = undefined,
    form: {
      getFieldProps,
      errors: { email: emailErrors },
      touched: { email: emailTouched },
    },
  }) => (
    <div className="group">
      <label className="email" htmlFor="email">
        Your email
      </label>
      <input id="email" name="email" type="email" {...getFieldProps('email')} />
      {is(hint) === false ||
        (is(strongHint) === false && (
          <p className="hint email">
            {is(hint) === false && hint}
            {is(hint) === false && is(strongHint) === false && <br />}
            {is(strongHint) === false && <strong>{strongHint}</strong>}
          </p>
        ))}
      {emailTouched && emailErrors ? (
        <p className="error email">{emailErrors}</p>
      ) : null}
    </div>
  )
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default Email
