// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import TextTransition, { presets } from 'react-text-transition'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
const SubmitButton = React.memo(
  ({
    form,
    state,
    submitMessage = 'Submit details',
    workingMessage = 'Working...',
    completedMessage = 'Request processed',
    errorMessage = 'Error!',
  }) => {
    const { started, finished } = state
    const { errors } = form
    const hasError = Object.keys(errors).length !== 0

    let displayMessage = submitMessage
    if (started === true) {
      displayMessage = workingMessage

      if (finished === true) {
        if (hasError === false) {
          displayMessage = completedMessage
        } else {
          displayMessage = errorMessage
        }
      }
    }

    return (
      <button type="submit" disabled={true}>
        {/*<TextTransition text={displayMessage} springConfig={presets.wobbly} />*/}
        {displayMessage}
      </button>
    )
  }
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default SubmitButton
